import React from 'react';
import {Link} from 'react-router-dom';

interface NavBarProps {}

export const NavBar: React.FC<NavBarProps> = ({}) => {
  return (
    <React.Fragment>
      <div>
        <Link to="/">Landing</Link>
      </div>
      <div>
        <Link to="/rules">Rules</Link>
      </div>
    </React.Fragment>
  );
};
