import React, {Suspense} from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {lazily} from 'react-lazily';
import {NavBar} from './components/NavBar';
import './App.css';
const {Landing} = lazily(() => import('./pages/Landing'));
const {Rules} = lazily(() => import('./pages/Rules'));
const {Error404} = lazily(() => import('./pages/Error404'));
const history = createBrowserHistory();

const Loading = () => <div>Loading...</div>;

interface AppProps {}

export const App: React.FC<AppProps> = ({}) => {
  return (
    <React.Fragment>
      <div className="App">
        <Router history={history}>
          <div>
            <NavBar />
            <Suspense fallback={Loading}>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/rules" component={Rules} />
                <Route component={Error404} />
              </Switch>
            </Suspense>
          </div>
        </Router>
      </div>
    </React.Fragment>
  );
};
